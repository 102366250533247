<template>
  <v-form ref="businessConfig">
    <v-row>
      <v-col class="pb-3 pt-0 text-center" cols="12">
        <h3 class="text-h6">
          Información del negocio
        </h3>
        <p class="ma-0 text-left">
          Completa los siguientes campos con la información de tu negoció.
        </p>
      </v-col>
      <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
        <v-text-field
          outlined
          type="text"
          ref="businessName"
          v-model="businessSetupForm.businessName"
          :rules="required"
          label="Nombre del negocio"
          @keyup.enter.native="$refs.businessMail.focus()"
        ></v-text-field>
      </v-col>
      <v-col class="pt-1 pb-0" cols="12" sm="12" md="12" lg="6" xl="3">
        <v-text-field
          outlined
          type="email"
          ref="businessMail"
          v-model="businessSetupForm.businessMail"
          :rules="businessMailRules"
          label="Correo de contacto del negocio"
          @keyup.enter.native="$refs.phoneBranchOffice.focus()"
        ></v-text-field>
      </v-col>
      <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          outlined
          type="tel"
          ref="phoneBranchOffice"
          v-model="businessSetupForm.phoneBranchOffice"
          :rules="phoneBranchOfficeRules"
          label="Teléfono del negocio"
          @keyup.enter.native="$refs.marketplaceURL.focus()"
        ></v-text-field>
      </v-col>
      <v-col class="py-1 mb-2" cols="12" sm="12" md="12" lg="6" xl="6">
        <p class="subheadline ma-0">Ingresa la URL de tu tienda Online</p>
        <v-card outlined class="d-flex align-center pa-2 py-4">
          <p class="ma-0" style="font-size: 16px;">ssencial.com/</p>
          <v-text-field
            height="20"
            class="ma-0 pa-0"
            type="text"
            ref="marketplaceURL"
            hide-details
            dense
            v-model="businessSetupForm.marketplaceURL"
            :rules="[validURL, onlyRequired, isValidaURL]"
            @input="checkValidaURL"
            @keyup.enter.native="$refs.categories.focus()"
          >
          </v-text-field>
          <template v-if="businessSetupForm.marketplaceURL">
            <v-fade-transition hide-on-leave>
              <v-icon
                class="ml-2"
                v-if="validBusinessURL"
                size="20"
                color="success"
              >
                check_circle
              </v-icon>
              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                    color="error"
                  >
                    error
                  </v-icon>
                </template>
                <span>La url que escribiste ya está siendo utilizada</span>
              </v-tooltip>
            </v-fade-transition>
          </template>
        </v-card>
        <p class="caption ma-0 ml-2">
          solo se permiten letras, números, - y _ ; y máximo 14 caracteres.
        </p>
      </v-col>
      <v-col cols="12" class="pt-0 pb-4" ref="daysSelected">
        <v-divider></v-divider>
        <p class="caption mb-0 mt-2">
          Selecciona los días de la semana y horarios en los que tu negocio
          labora
        </p>
        <v-row class="d-flex justify-center mt-2">
          <v-item-group multiple v-model="businessSetupForm.daysSelected">
            <v-item
              v-for="day in businessSetupForm.days"
              :key="day.day"
              v-slot="{ active, toggle }"
            >
              <v-chip
                active-class="primary"
                :input-value="active"
                @click="toggle"
                class="mr-1"
              >
                {{ day.letter }}
              </v-chip>
            </v-item>
          </v-item-group>
        </v-row>
        <div class="text-center mt-2">
          Horario
        </div>
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-menu
              ref="openTimeMenu"
              v-model="openTimeMenu"
              :close-on-content-click="false"
              :return-value.sync="businessSetupForm.openTime"
              bottom
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="businessSetupForm.openTime"
                  hide-details
                  label="Apertura"
                  :rules="required"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="openTimeMenu"
                v-model="businessSetupForm.openTime"
                @click:minute="
                  $refs.openTimeMenu.save(businessSetupForm.openTime)
                "
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="pb-0">
            <v-menu
              ref="closeTimeMenu"
              v-model="closeTimeMenu"
              :close-on-content-click="false"
              :return-value.sync="businessSetupForm.closeTime"
              bottom
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="businessSetupForm.closeTime"
                  :rules="required"
                  hide-details
                  label="Cierre"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="closeTimeMenu"
                v-model="businessSetupForm.closeTime"
                @click:minute="
                  $refs.closeTimeMenu.save(businessSetupForm.closeTime)
                "
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="6">
        <v-select
          v-model="businessSetupForm.categorieSelected"
          :rules="required"
          ref="categories"
          item-text="categorie"
          item-value="value"
          :items="categories"
          label="Categoria"
          hide-details
          outlined
        ></v-select>
      </v-col>
      <v-col class="py-1" cols="12" sm="12" md="12" lg="5" xl="5">
        <div class="text-right my-1" v-if="!$vuetify.breakpoint.lgAndUp">
          <v-tooltip top max-width="320">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="grey" right dark v-bind="attrs" v-on="on">
                help
              </v-icon>
            </template>
            <span
              >Ingresa un nombre de sucursal para provisionar los recursos
              necesarios para cada Inventario, Punto de venta, etc.</span
            >
          </v-tooltip>
        </div>
        <v-text-field
          outlined
          type="text"
          ref="branchOfficeName"
          v-model="businessSetupForm.branchOfficeName"
          :rules="required"
          label="Nombre de la sucursal"
          @keyup.enter.native="$refs.phoneBranchOffice.focus()"
        ></v-text-field>
      </v-col>
      <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp">
        <div class="text-right my-1">
          <v-tooltip top max-width="320">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="grey" right dark v-bind="attrs" v-on="on">
                help
              </v-icon>
            </template>
            <span
              >Ingresa un nombre de sucursal para provisionar los recursos
              necesarios para cada Inventario, Punto de venta, etc.</span
            >
          </v-tooltip>
        </div>
      </v-col>
      <v-col class="py-1" cols="12">
        <p class="ma-0 caption">Dirección de tu negocio principal</p>
        <v-text-field
          id="ship-address"
          outlined
          :rules="[onlyRequired, validLocation]"
          autocomplete="off"
          @input="checkLocationValid"
          v-model="businessSetupForm.full_address"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-expand-transition>
          <v-row v-show="businessSetupForm.map_point">
            <v-col class="pb-1 pt-0" cols="12">
              <p class="caption mb-1">
                Puedes mover el puntero
                <v-icon color="black">location_on</v-icon> para precisar la
                ubicación de tu negocio
              </p>
              <AppMapDraggableMarker
                :istSetupCustomer="true"
                :myLatlng="businessSetupForm.map_point"
              />
              <div class="mt-3 caption">
                Completa los campos correspondientes de la ubicación de tu
                negocio
              </div>
            </v-col>
            <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="4">
              <v-text-field
                outlined
                type="text"
                ref="street"
                v-model="businessSetupForm.street"
                :rules="required"
                label="Calle"
                @keyup.enter.native="$refs.streetReference.focus()"
              ></v-text-field>
            </v-col>
            <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="4">
              <v-text-field
                outlined
                type="text"
                ref="streetReference"
                v-model="businessSetupForm.streetReference"
                :rules="required"
                label="Referencias"
                @keyup.enter.native="$refs.postalCode.focus()"
              ></v-text-field>
            </v-col>
            <v-col class="py-1" cols="6" sm="6" md="6" lg="6" xl="4">
              <v-text-field
                outlined
                type="number"
                ref="postalCode"
                v-model="businessSetupForm.postalCode"
                :rules="required"
                label="Código postal"
                @keyup.enter.native="$refs.city.focus()"
              ></v-text-field>
            </v-col>
            <v-col class="py-1" cols="6" sm="6" md="6" lg="6" xl="4">
              <v-text-field
                outlined
                type="text"
                ref="city"
                v-model="businessSetupForm.city"
                :rules="required"
                label="Ciudad"
                @keyup.enter.native="$refs.streetNumber.focus()"
              ></v-text-field>
            </v-col>
            <v-col class="py-1" cols="6" sm="6" md="6" lg="6" xl="4">
              <v-text-field
                outlined
                type="number"
                ref="streetNumber"
                v-model="businessSetupForm.streetNumber"
                :rules="required"
                label="Numero exterior"
                @keyup.enter.native="$refs.state.focus()"
              ></v-text-field>
            </v-col>
            <v-col class="py-1" cols="6" sm="6" md="6" lg="6" xl="4">
              <v-select
                v-model="businessSetupForm.stateSelected"
                :rules="required"
                ref="state"
                :items="getStates"
                label="Estado"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  onlyNumbers,
  emailValid,
  validURL
} from "@/services/ValidatorService";
import { databaseDefault } from "@/config/firebase";
import { errorMessage } from "@/services/MessageService";
import { mapActions, mapGetters, mapState } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";
import { app } from "@/config/globals";
import MapDraggableMarker from "@/components/app/MapDraggableMarker";
let autocomplete;

export default {
  data() {
    return {
      required: [required],
      onlyRequired: required,
      validURL: validURL,
      isValidaURL: true,
      validLocation: true,
      validBusinessURL: false,
      businessMailRules: [required, emailValid],
      categories: [
        { categorie: "Belleza", value: "beauty" },
        { categorie: "Indumentaria", value: "clothing" },
        { categorie: "Electrónica", value: "electronics" },
        { categorie: "Muebles", value: "furniture" },
        { categorie: "Víveres", value: "groceries" },
        { categorie: "Artesanías", value: "handcrafts" },
        { categorie: "Joyería", value: "jewelry" },
        { categorie: "pinturas", value: "painting" },
        { categorie: "fotografía", value: "photography" },
        { categorie: "Restaurantes", value: "restaurants" },
        { categorie: "Otros alimentos y bebidas", value: "other_food_drink" },
        { categorie: "Servicios", value: "services" },
        { categorie: "Deportes", value: "sports" },
        { categorie: "Juguetes", value: "toys" },
        { categorie: "Servicios virtuales", value: "virtual_services" },
        { categorie: "Otro", value: "other" }
      ],
      phoneBranchOfficeRules: [
        required,
        minLength(10),
        maxLength(10),
        onlyNumbers
      ],
      stateRules: [required],
      openTimeMenu: false,
      closeTimeMenu: false,
      businessURLsData: [],
      databaseRef: null
    };
  },
  computed: {
    ...mapState("business", ["businessSetupForm"]),
    ...mapGetters("app", ["getStates"])
  },
  created() {
    this.getMetadaBusinessURL();
  },
  mounted() {
    this.initMapLoader();
  },
  methods: {
    ...mapActions("business", ["changeFormBusinessData"]),
    async getMetadaBusinessURL() {
      try {
        this.businessURLsData = [];
        this.databaseRef = await databaseDefault("business_marketplace");
        this.databaseRef.on("value", snapshot => {
          if (snapshot.exists()) {
            let businessURLS = Object.keys(snapshot.val());
            this.businessURLsData = businessURLS;
          } else {
            this.validBusinessURL = true;
          }
        });
      } catch {
        errorMessage({
          message:
            "Error al obtener inforamación de ssencial, code: URLBusiness"
        });
      }
    },
    checkValidaURL(ulr) {
      if (this.businessURLsData.includes(ulr)) {
        this.validBusinessURL = false;
        this.isValidaURL = () => false || "URL ya registrada";
      } else {
        this.validBusinessURL = true;
        this.isValidaURL = true;
      }
    },
    checkLocationValid() {
      if (this.businessSetupForm.map_point) {
        this.validLocation = true;
      } else {
        this.validLocation = () => false || "Selecciones una ubicación";
      }
    },
    // :: Maps Methods
    async initMapLoader() {
      const fieldContainer = document.querySelector("#ship-address");
      const loader = new Loader({
        apiKey: app.maps_api_key,
        version: "weekly",
        libraries: ["places"]
      });
      // eslint-disable-next-line
      if (typeof google === "object" && typeof google.maps === "object") {
        // eslint-disable-next-line
        autocomplete = new google.maps.places.Autocomplete(fieldContainer, {
          componentRestrictions: { country: ["mx"] },
          fields: ["address_components", "geometry"],
          types: ["address"]
        });
      } else {
        await loader.load();
        // eslint-disable-next-line
        autocomplete = new google.maps.places.Autocomplete(fieldContainer, {
          componentRestrictions: { country: ["mx"] },
          fields: ["address_components", "geometry"],
          types: ["address"]
        });
      }
      autocomplete.addListener("place_changed", this.fillInAddress);
    },
    fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = autocomplete.getPlace();
      let fullAddress = "";
      for (const placeAddress of place.address_components) {
        const placeType = placeAddress.types[0];
        switch (placeType) {
          case "route": {
            this.changeFormBusinessData({
              key: "street",
              value: placeAddress.long_name
            });
            fullAddress += placeAddress.long_name + ", ";
            break;
          }
          case "postal_code": {
            this.changeFormBusinessData({
              key: "postalCode",
              value: placeAddress.long_name
            });
            break;
          }
          case "sublocality_level_1": {
            fullAddress += placeAddress.long_name + ", ";
            break;
          }
          case "locality": {
            this.changeFormBusinessData({
              key: "city",
              value: placeAddress.long_name
            });
            fullAddress += placeAddress.short_name + ", ";
            break;
          }
          case "administrative_area_level_1": {
            this.changeFormBusinessData({
              key: "stateSelected",
              value: placeAddress.long_name
            });
            fullAddress += placeAddress.short_name;
            break;
          }
        }
      }
      this.changeFormBusinessData({ key: "full_address", value: fullAddress });
      const lat = autocomplete.getPlace().geometry.location.lat();
      const lng = autocomplete.getPlace().geometry.location.lng();
      if (lat && lng) this.validLocation = true;
      this.changeFormBusinessData({ key: "map_point", value: { lat, lng } });
    }
  },
  components: {
    AppMapDraggableMarker: MapDraggableMarker
  },
  beforeDestroy() {
    // :: Detach databaseRef listener
    this.databaseRef.off("value");
    this.databaseRef = null;
  }
};
</script>

<style></style>
