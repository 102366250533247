<template>
  <div>
    <div
      class="text-subtitle-2 hover-link text-center"
      :class="{
        'pt-10 pb-10': !imageData.imageSrc,
        dropZone: !$vuetify.theme.dark,
        'dropZone-white': $vuetify.theme.dark
      }"
    >
      <div v-if="!imageData.imageSrc">
        <v-icon>cloud_upload</v-icon> Sube tu imagen
      </div>
      <v-img
        v-if="imageData.imageSrc"
        :src="imageData.imageSrc"
        width="100%"
        :max-height="heightImage"
        contain
        class="align-center"
      >
      </v-img>
    </div>
    <v-dialog v-model="loadImage" persistent width="330">
      <v-card color="primary" dark>
        <v-card-text class="py-4 text-center">
          <span class="text-subtitle-2 white--text"
            >Estamos procesando tu imagen 📸</span
          >
          <v-progress-linear
            indeterminate
            color="white"
            class="my-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <input
      type="file"
      accept="image/*"
      @change="uploadImage"
      v-show="false"
      ref="upload"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("app", ["imageData", "loadImage"])
  },
  methods: {
    ...mapActions("app", ["handleImageCompression"]),
    uploadImage(event) {
      this.handleImageCompression(event);
    }
  },
  props: {
    heightImage: {
      default: "200"
    }
  }
};
</script>

<style scoped>
.dropZone {
  border: 2px dashed rgb(0, 0, 0);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}
.dropZone-white {
  border: 2px dashed rgb(255, 255, 255);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.hover-link:hover {
  cursor: pointer;
}
</style>
