<template>
  <v-form ref="userDataValid">
    <v-row>
      <v-col class="pb-3 pt-0 text-center" cols="12">
        <h3 class="text-h6">
          Información personal
        </h3>
        <p class="ma-0 text-left">
          Completa los siguientes campos con tu información personal.
        </p>
      </v-col>
      <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          outlined
          type="text"
          ref="firstName"
          v-model="businessSetupForm.firstName"
          :rules="required"
          label="Nombre(s)"
          @keyup.enter.native="$refs.lastName.focus()"
        ></v-text-field>
      </v-col>
      <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          outlined
          type="text"
          ref="lastName"
          v-model="businessSetupForm.lastName"
          :rules="required"
          label="Apellidos"
          @keyup.enter.native="$refs.phone.focus()"
        ></v-text-field>
      </v-col>
      <v-col class="py-1 pb-0" cols="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          outlined
          ref="password"
          v-model="businessSetupForm.password"
          label="Contraseña"
          :rules="passRules"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-col>
      <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          outlined
          type="tel"
          ref="phone"
          v-model="businessSetupForm.phone"
          :rules="phoneRules"
          label="Teléfono"
          @keyup.enter.native="$refs.whatsapp.focus()"
        ></v-text-field>
      </v-col>
      <v-col class="py-1 pb-0" cols="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          outlined
          type="tel"
          ref="whatsapp"
          v-model="businessSetupForm.whatsapp"
          label="Whatsapp"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="6">
        <p class="mt-3 mb-1 text-center">
          Imagen de pefil <v-icon>account_circle</v-icon>
        </p>
        <v-card @click="uploadImage" elevation="0">
          <app-upload-images ref="uploadImages"></app-upload-images>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  onlyNumbers
} from "@/services/ValidatorService";
import UploadImages from "@/components/app/UploadImages";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      required: [required],
      phoneRules: [required, minLength(10), maxLength(10), onlyNumbers],
      showPassword: false,
      passRules: [required, minLength(8)]
    };
  },
  methods: {
    ...mapActions("app", ["uploadImageProfileToStorage", "resetImageData"]),
    uploadImage() {
      this.$refs.uploadImages.$refs.upload.click();
    }
  },
  components: {
    AppUploadImages: UploadImages
  },
  computed: {
    ...mapState("app", ["imageData", "loadImage"]),
    ...mapState("business", ["businessSetupForm"])
  }
};
</script>

<style></style>
