<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12">
        <h3 class="text-h5 ml-1 mb-1 font-weight-medium">
          Configuración
        </h3>
        <p class="ma-0 ml-1 text-justify" v-if="!showLoadUI">
          Tomate unos minutos para completar la siguiente información y poder
          disfrutar al máximo de la Tecnología de
          <span class="font-weight-bold">SSENCIAL.</span>
        </p>
      </v-col>
      <v-col cols="12" class="py-1 d-flex align-center justify-center">
        <v-chip :color="getColorChip(1, stepSetup)">
          Cuenta
          <v-avatar v-if="stepSetup > 1" class="ml-1">
            <v-icon>check_circle</v-icon>
          </v-avatar>
        </v-chip>
        <v-icon class="mx-1" color="grey lighten-1" small>east</v-icon>
        <v-chip :color="getColorChip(2, stepSetup)">
          Negocio
          <v-avatar v-if="stepSetup > 2" class="ml-1">
            <v-icon>check_circle</v-icon>
          </v-avatar>
        </v-chip>
        <v-icon class="mx-1" color="grey lighten-1" small>east</v-icon>
        <v-chip :color="getColorChip(3, stepSetup)">
          Planes
          <v-avatar v-if="stepSetup > 3" class="ml-1">
            <v-icon>check_circle</v-icon>
          </v-avatar>
        </v-chip>
      </v-col>
      <v-col
        cols="12"
        class="d-flex align-center justify-center"
        v-if="!showLoadUI"
      >
        <v-stepper v-model="stepSetup">
          <v-stepper-items>
            <v-stepper-content step="1">
              <app-user-data ref="user"></app-user-data>
            </v-stepper-content>

            <v-stepper-content step="2">
              <app-business-data ref="business"></app-business-data>
            </v-stepper-content>

            <v-stepper-content step="3" class="px-0 py-2">
              <app-plan-data></app-plan-data>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col cols="12" v-if="showLoadUI">
        <v-container>
          <p class="font-weight-medium text-center">
            Espera unos segundos en lo que terminamos de configurar tu negocio
            😉
          </p>
          <div class="d-flex justify-space-between mb-3">
            <p class="ma-0">Configurando Cuenta</p>
            <div v-if="loadStep1">
              <v-progress-circular
                size="20"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-icon class="ml-2" v-else>check_circle</v-icon>
          </div>
          <div class="d-flex justify-space-between mb-3">
            <p class="ma-0">Configurando información del negocio</p>
            <div v-if="loadStep2">
              <v-progress-circular
                size="20"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-icon class="ml-2" v-else>check_circle</v-icon>
          </div>
          <div class="d-flex justify-space-between mb-3">
            <p class="ma-0">Provicionando espacios</p>
            <div v-if="loadStep3">
              <v-progress-circular
                size="20"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-icon class="ml-2" v-else>check_circle</v-icon>
          </div>
          <div class="d-flex justify-space-between">
            <p class="ma-0">
              Configurando ajustes generales de la app
            </p>
            <div v-if="loadStep4">
              <v-progress-circular
                size="20"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon class="ml-2" v-if="finalResult">check_circle</v-icon>
              <v-icon class="ml-2" v-else>dangerous</v-icon>
            </div>
          </div>
        </v-container>
      </v-col>
      <v-col class="py-1" cols="12">
        <div class="d-flex" v-if="!showLoadUI">
          <v-btn
            @click="goBackStep"
            class="mr-1"
            outlined
            v-if="stepSetup > 1 && stepSetup < 4"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-btn color="primary" @click="goNextStepAction" class="flex-grow-1">
            {{ stepSetup | getNextName }}
          </v-btn>
        </div>
        <div v-if="finalLoadFinish">
          <p class="mb-2 caption">
            Haz clic en finalizar para redireccionarte al login para ingresar
            con tus nuevos datos a tu app SSENCIAL
          </p>
          <v-btn
            block
            color="success"
            @click="finishBusinessSetup"
            class="flex-grow-1"
          >
            Finalizar
          </v-btn>
        </div>
        <p class="caption mb-2 mt-5 text-center text--secondary">
          Si tienes problemas para subir tu información puedes contactar con el
          <a
            class="font-weight-bold black--text"
            href="https://ayuda.ssencial.com"
            >Centro de ayuda SSENCIAL</a
          >
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UserData from "../components/business-setup/UserData";
import BusinessData from "../components/business-setup/BusinessData";
import PlanData from "../components/business-setup/PlanData";
import { showMessage } from "@/services/MessageService";

export default {
  name: "Login",
  data() {
    return {
      stepSetup: 1,
      showLoadUI: false,
      loadStep1: true,
      loadStep2: true,
      loadStep3: true,
      loadStep4: true,
      finalResult: true
    };
  },
  components: {
    AppUserData: UserData,
    AppBusinessData: BusinessData,
    AppPlanData: PlanData
  },
  methods: {
    ...mapActions("business", ["setFirstBusinessData"]),
    ...mapActions("user", ["signOut"]),
    ...mapActions("app", ["uploadImageProfileToStorage", "resetImageData"]),
    getColorChip(option, stepSetup) {
      switch (option) {
        case 1:
          if (stepSetup === 1) return "primary";
          if (stepSetup > 1) return "success";
          else return "";
        case 2:
          if (stepSetup === 2) return "primary";
          if (stepSetup > 2) return "success";
          else return "";
        case 3:
          if (stepSetup === 3) return "primary";
          if (stepSetup > 3) return "success";
          else return "";
      }
    },
    goNextStepAction() {
      switch (this.stepSetup) {
        case 1:
          if (this.$refs.user.$refs.userDataValid.validate()) {
            this.stepSetup++;
            this.$vuetify.goTo(0);
          } else {
            this.$vuetify.goTo(this.$refs.user, { offset: 30 });
          }
          break;
        case 2:
          if (this.$refs.business.$refs.businessConfig.validate()) {
            if (this.businessSetupForm.daysSelected.length === 0) {
              showMessage({
                message:
                  "Por favor selecciona los días en lo que tu negocio labora",
                color: "warning"
              });
              this.$vuetify.goTo(this.$refs.business, { offset: -30 });
              break;
            }
            if (this.businessSetupForm.map_point === null) {
              showMessage({
                message:
                  "Por favor selecciona una ubicación del menu despegable de ubicaciones.",
                color: "warning"
              });
              break;
            }
            this.stepSetup++;
            this.$vuetify.goTo(0);
          } else {
            this.$vuetify.goTo(this.$refs.business, { offset: 30 });
          }
          break;
        case 3:
          if (this.businessSetupForm.plan) {
            this.stepSetup++;
            this.completeData();
          } else {
            showMessage({
              message:
                "Por favor selecciona un plan para finalizar tu registro",
              color: "warning"
            });
          }
          break;
      }
    },
    goBackStep() {
      this.stepSetup--;
      setTimeout(() => {
        switch (this.stepSetup) {
          case 1:
            this.$vuetify.goTo(this.$refs.user, { offset: 30 });
            break;
          case 2:
            this.$vuetify.goTo(this.$refs.business, { offset: 30 });
            break;
        }
      }, 100);
    },
    async completeData() {
      // data to upload
      const data = {
        first_name: this.businessSetupForm.firstName,
        last_name: this.businessSetupForm.lastName,
        phone: this.businessSetupForm.phone,
        password: this.businessSetupForm.password,
        whatsapp: this.businessSetupForm.whatsapp,
        business_name: this.businessSetupForm.businessName,
        business_mail: this.businessSetupForm.businessMail,
        categorie: this.businessSetupForm.categorieSelected,
        marketplace_namespace: this.businessSetupForm.marketplaceURL,
        branch_office_name: this.businessSetupForm.branchOfficeName,
        location: {
          street_number: this.businessSetupForm.streetNumber,
          street_name: this.businessSetupForm.street,
          city_name: this.businessSetupForm.city,
          state_name: this.businessSetupForm.stateSelected,
          postal_code: this.businessSetupForm.postalCode,
          reference: this.businessSetupForm.streetReference,
          full_address: this.businessSetupForm.full_address,
          map_point: this.businessSetupForm.map_point
        },
        phone_branch_office: this.businessSetupForm.phoneBranchOffice,
        business_hours: {},
        business_hours_data: {
          days: this.businessSetupForm.daysSelected,
          open: this.businessSetupForm.openTime,
          close: this.businessSetupForm.closeTime
        },
        plan: this.businessSetupForm.plan
      };
      this.businessSetupForm.days.forEach(day => {
        if (this.businessSetupForm.daysSelected.includes(day.value)) {
          data.business_hours[day.day] = [
            {
              open: this.businessSetupForm.openTime,
              close: this.businessSetupForm.closeTime
            }
          ];
        }
      });
      this.showLoadUI = true;
      this.loadingUI();
      const response = await this.setFirstBusinessData(data);
      this.finalResult = response;
      this.loadStep4 = false;
    },
    finishBusinessSetup() {
      this.signOut();
    },
    async loadingUI() {
      await this.timeout(2000);
      this.loadStep1 = false;
      await this.timeout(2000);
      this.loadStep2 = false;
      await this.timeout(2000);
      this.loadStep3 = false;
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  beforeMount() {
    if (!this.bussinessInfo) {
      this.$router.push({ name: "home" });
    }
  },
  computed: {
    ...mapState("user", ["bussinessInfo"]),
    ...mapState("app", ["imageData", "loadImage"]),
    ...mapState("business", ["businessSetupForm"]),
    finalLoadFinish() {
      if (this.showLoadUI) {
        if (
          !this.loadStep1 &&
          !this.loadStep2 &&
          !this.loadStep3 &&
          !this.loadStep4
        )
          return true;
        else return false;
      } else {
        return false;
      }
    }
  },
  filters: {
    getNextName(step) {
      switch (step) {
        case 1:
          return "Continuar";
        case 2:
          return "Elegir plan";
        case 3:
          return "Finalizar configuración";
      }
    }
  }
};
</script>
