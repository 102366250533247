<template>
  <v-row justify="center" class="px-1">
    <v-col class="pb-3 pt-2 text-center" cols="12">
      <v-container class="py-0">
        <h3 class="text-h6">
          Plan SSENCIAL
        </h3>
        <p class="ma-0 text-left">
          Elige un plan el cual se adapte más a las necesidades de tu negocio,
          siempre que lo desees puedes cambiarlo.
        </p>
      </v-container>
    </v-col>
    <v-col cols="12">
      <div class="text-center" v-if="loader">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div v-for="plan in plans" :key="plan.id + 'plan'" class="my-2 mx-4">
        <v-card
          :elevation="currentSelectPlan(plan.type) ? '10' : ''"
          :dark="currentSelectPlan(plan.type)"
          class="text-center d-flex flex-column justify-space-between"
          height="100%"
          :max-width="$vuetify.breakpoint.width < 673 ? '100%' : '320'"
        >
          <v-container class="px-5">
            <v-chip
              v-if="plan.mostPopular"
              small
              outlined
              color="success"
              style="position: absolute; right: 8px;"
            >
              Popular <v-icon small right>star</v-icon>
            </v-chip>
            <div class="d-flex align-center justify-center my-3 mb-4">
              <img
                src="../../assets/ssencial-logo.svg"
                alt="ssencial"
                height="24px"
              />
              <p class="text-h4 ma-0 ml-1">{{ plan.type }}</p>
            </div>
            <p class="ma-0 font-weight-light">{{ plan.description }}</p>
          </v-container>
          <v-container class="pb-0 px-5">
            <template v-for="(feature, index) in plan.features">
              <p class="ma-0 font-weight-medium" :key="index + '-title'">
                {{ feature.title }}
              </p>
              <p
                :key="index + '.-desc'"
                class="caption mb-0"
                :class="{ 'mb-3': plan.features.length > index + 1 }"
              >
                {{ feature.decription }}
              </p>
            </template>
          </v-container>
          <v-container class="px-5">
            <div class="d-flex justify-center align-center">
              <p class="text-h6 font-weight-light ma-0 ml-4">$</p>
              <p class="ma-0">
                <span
                  class="font-weight-bold"
                  style="font-size: 2.5rem !important;"
                  >{{ plan.price }}</span
                >
                <span class="caption font-weight-medium">MXN</span>
              </p>
            </div>
            <div class="ma-0 caption" style="margin-top: -12px !important;">
              Mensual
            </div>
            <v-btn
              small
              :outlined="noSelectedPlan(plan.type)"
              block
              color="primary"
              class="my-2"
              @click="selectedPlan(plan)"
              >Elegir plan</v-btn
            >
          </v-container>
        </v-card>
      </div>
    </v-col>
    <v-snackbar v-model="snackbarPlan" timeout="2000">
      <v-icon style="position: absolute; right: 10px;">check_circle</v-icon>
      <p class="ma-0 text-center">Plan seleccionado</p>

      <div class="d-flex align-center justify-center">
        <img
          src="../../assets/ssencial-logo-white.svg"
          alt="ssencial"
          height="18px"
        />
        <p class="text-h5 ma-0 ml-1">{{ currentPlan.type }}</p>
      </div>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { firestore } from "@/config/firebase";

export default {
  data() {
    return {
      snackbarPlan: false,
      currentPlan: {
        type: ""
      },
      plans: [],
      loader: true
    };
  },
  beforeMount() {
    this.getPlans();
  },
  methods: {
    async getPlans() {
      this.loader = true;
      const snapshotProducts = await firestore("products")
        .orderBy("price")
        .get();
      for (const product of snapshotProducts.docs) {
        this.plans.push(product.data());
      }
      this.loader = false;
    },
    selectedPlan(plan) {
      this.currentPlan = plan;
      this.businessSetupForm.plan = plan.id;
      this.snackbarPlan = true;
    },
    noSelectedPlan(type) {
      if (!this.currentPlan.type) return false;
      if (this.currentPlan.type != type) return true;
      else return false;
    },
    currentSelectPlan(type) {
      if (!this.currentPlan.type) return false;
      if (this.currentPlan.type != type) return false;
      else return true;
    }
  },
  computed: {
    ...mapState("business", ["businessSetupForm"])
  }
};
</script>

<style></style>
